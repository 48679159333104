.box-layout {
  background-size: cover;
  // display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $desktop-breakpoint) {
    max-width: 730px;
  }
}

.box-layout__border {
  position: relative;
  padding: 1rem;
  border: 0.2rem solid #ececec;
  border-radius: 8px;
  margin-right: 0;
  margin-left: 0;
  color: #212529;
}

.box-layout__box {
  background: fade-out(#e9ecef, 0.15);
  border-radius: 8px;
  padding: $l-size $m-size;
  text-align: center;
  // width: 25rem;
  height: min-content;

  @media (min-width: $desktop-breakpoint) {
    padding: 4rem 2rem;
    width: auto;
  }
}

.box-layout__title {
  margin: 0 0 $m-size 0;
  line-height: 1;
}
