.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;

  @media (min-width: $desktop-breakpoint) {
    adding-right: 0;
    padding-left: 0;
  }
}
