.ul {
  column-count: 3;
  list-style: none;
  padding-top: $m-size;
  padding-left: $s-size;
  padding-right: $s-size;
}

.li {
  text-align: center;
}
