.header {
  font-size: 24px;
  color: #777;
  margin-top: 0;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
